<script>
import axios from 'axios';
import { orderCategories } from '@/misc.js';
import {i18n} from "vue-lang-router";

export default {
  name: 'library-start',

  data() {
    return {
      categories: true,
    };
  },

  created() {
    const lang = i18n.locale;
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/category/${lang}`)
      .then((res) => {
        this.categories = orderCategories(res.data);
      })
      .catch(console.error);
    document.title = i18n.t("tempus");

  },
  methods: {
    getCategoryImagePath(extId) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/categories/${extId}.jpg`);
    },
  },
};
</script>

<template>
 <div class="illustration-info-container">
  <div class="illustration-info" style="background: white !important;">
    <div class="start-title">  <h1 >Bildkategorier</h1></div>
    <div class="start-text"> {{ $i18n.locale == 'en' ? "The pictures of Tempus Picture Agency are arranged in twelve top categories." : "Bilderna i Tempus Bildbyrå är ordnade i tolv toppkategorier." }}</div>
    <div class="start-box bg-white">
      <localized-link
        v-for="(category, i) in categories"
        class="block text-2xl mb-2 md:text-3xl hover:text-blue-500" style="height: 180px; text-align: center;"
        :to="{ name: 'library', params: { category: $i18n.locale == 'en' ? category.full_path_en.toLowerCase().split('/') : category.full_path_sv.toLowerCase().split('/') } }"
        :key="i"
      >
        <img :src="getCategoryImagePath(category.ext_id)">
        <span class="categoryName"> {{ $i18n.locale == 'en' ? category.title_en : category.title_sv }} ({{ category.images_count }})</span>
      </localized-link>
    </div>
    </div></div>
</template>

<style lang="scss" scoped>
.library {

  .cat-link {

    &:hover {
      color: $dark-blue;
    }
  }
}

.illustration-info-container{
  text-align: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);

  -ms-overflow-style: auto !important;
 scrollbar-width: auto !important;
.illustration-info{
    // width: 75%;
     margin: 0 25%;
    height: 100%;
    padding-bottom: 70px;
    background-color: #fff;
  
     @media only screen and (max-width: 992px){
      margin: 0 20px;
      width: 100%;
      height: 100%;
      padding-bottom: 0;
    }

    @media only screen and (max-width: 600px){
      width: auto;
    }
 
}
}



.start-title{
padding-top: 50px;
  margin-left: 25px;
  margin-right: 20px;
  text-align: left;

  @media screen and (max-width: $bp-mobile){
    margin-left: 10px;
  }

  h1{
 font-size: 30px;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 12px;
  }
 
}

.start-text {
  text-align: left;
  font-size: 16px;
  line-height: 29px;
  font-weight: 500;
  // margin-top: 50px;
  margin-left: 25px;

  @media screen and (max-width: $bp-mobile){
    margin-left: 10px;
  }
}

.start-box{
  display: flex; 
  flex-wrap: wrap; 
  row-gap: 30px; 
  column-gap: 10px; 
  // margin-left: 50px;  
  align-items: center; 
  place-content: flex-start; 
  background: white;
   align-items: baseline;
        // justify-content: center;
        margin: 25px 0 0px 25px;

  @media screen and (max-width: $bp-mobile){
   
        align-items: center;
        justify-content: center;
        margin: 25px 0 0;
        padding-bottom: 50px;
        row-gap: 20px; 
        column-gap: 5px; 
  }
}

.categoryName{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 7px 0;
}
</style>
